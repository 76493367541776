import {
    VIEW_TICKET_SUCCESS,
    VIEW_TICKET_FAIL,
    CLEAR_VIEW_TICKET
} from '../actions/types';

const initialState = {
    viewTicket: {}
};



export default function viewTicket(state = initialState, action) {
    const { type, viewTicket } = action;

    switch(type) {
        case VIEW_TICKET_SUCCESS:
            return {
                ...state,
                viewTicket: viewTicket
            }
        case CLEAR_VIEW_TICKET:
        case VIEW_TICKET_FAIL:
            return {
                ...state,
                viewTicket: {}
            }
        default:
            return state
    }
}
