import axios from 'axios';
import { toast } from 'react-toastify';
import moment from "moment";

import{
    VALID_UPLOADS_SUCCESS,
    VALID_UPLOADS_FAIL,
    CLEAR_VALID_UPLOADS,
    GET_UPLOAD_ID_SUCCESS,
    GET_UPLOAD_ID_FAIL,
    CLEAR_UPLOAD_ID,
    GET_UPLOAD_SUCCESS,
    GET_UPLOAD_FAIL,
    GET_OLD_UPLOAD_SUCCESS,
    GET_OLD_UPLOAD_FAIL,
    CLEAR_UPLOAD,
    UPDATE_UPLOAD_SUCCESS,
    UPDATE_UPLOAD_FAIL,
    GET_VIEWDATE_SUCCESS,
    GET_VIEWDATE_FAIL,
} from './types';


// CREATE UPLOAD LIVES INSIDE containers/FileUpload.jsx

export const getValidUploads = (ticketID) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/uploads/valid/${ticketID}`, config);
        dispatch({
            type: VALID_UPLOADS_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: VALID_UPLOADS_FAIL
        });
        // console.log(err)
        // toast.error('Valid Uploads Failed - Try Refreshing Page')
    }
};

export const clearValidUploads = () => dispatch => {
    dispatch({
        type: CLEAR_VALID_UPLOADS
    })
}

// Grabs Upload ID from TicketView
export const getUploadID = (uploadID) => async dispatch => {
    if(!uploadID) {
        dispatch({
            type: GET_UPLOAD_ID_FAIL
        });

    } else {
        dispatch({
            type: GET_UPLOAD_ID_SUCCESS,
            payload: uploadID
        });
    }
};

// Clears UploadID upon TicketView Page Load
export const clearUploadID = () => dispatch => {
    dispatch({
        type: CLEAR_UPLOAD_ID
    })
};


export const getUpload = (uploadID) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/uploads/stream/${uploadID}`, config);
        dispatch({
            type: GET_UPLOAD_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch(getOldUpload(uploadID));
    }
};

export const getOldUpload = (uploadID) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/uploads/oldstream/${uploadID}`, config);
        dispatch({
            type: GET_OLD_UPLOAD_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: GET_OLD_UPLOAD_FAIL
        });

        toast.error('Get Upload Failed - Try Refreshing Page')
    }
}

// Clears Upload base64 data
export const clearUpload = () => dispatch => {
    dispatch({
        type: CLEAR_UPLOAD
    })
};

// Update expiration_date
export const updateUpload = (uploadID, serverTime) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    const serverDateTime = moment(serverTime);
    let viewDate = JSON.stringify(serverDateTime.format("YYYY-MM-DD HH:mm:ss"));
    const body = `{"view_date": ${viewDate}}`;

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/uploads/update/${uploadID}/`, body, config);
        dispatch({
            type: UPDATE_UPLOAD_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: UPDATE_UPLOAD_FAIL
        });
        console.log(err)
        toast.error('Update View Date Failed - Please contact TCOLE')
    }
};


export const getViewDate = (uploadID) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/uploads/view-date/${uploadID}`, config);
        dispatch({
            type: GET_VIEWDATE_SUCCESS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: GET_VIEWDATE_FAIL
        });
        console.log(err)
    }
}


export const noViewReport = () => {
    const config = {
        headers: {
            'Content-Type': 'text/csv',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    }; 
    axios.get(`${process.env.REACT_APP_API_URL}/api/uploads/no-view-report/`, config)
    .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv'});
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.download = 'TSS_uploads_without_views_report.csv';
        tempLink.setAttribute('taget', '_blank');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    })
    .catch((error) => {
        console.log(error);
        console.log(error.response);
    })
}

