import axios from 'axios';
import { toast } from 'react-toastify';
import {
    DEPT_ACCOUNTS_SUCCESS,
    DEPT_ACCOUNTS_FAIL
} from './types';

export const deptAccounts = (deptName) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 


        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/department-accounts/${deptName}`, config);

            if(res.data.deptUserTotal === 0) {
                toast.error('Department Lookup Failed - Invalid Name or Department non-existent')
                dispatch({
                    type: DEPT_ACCOUNTS_FAIL
                });
            } else {
                dispatch({
                    type: DEPT_ACCOUNTS_SUCCESS,
                    payload: res.data
                });
            };
        } catch (err) {
            dispatch({
                type: DEPT_ACCOUNTS_FAIL
            });
            toast.error('Department Lookup Failed - Invalid Name or Department non-existent')
        }
    } else {
        return;
    }
};
