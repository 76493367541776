import React, { useEffect } from "react";
import { Redirect, Link } from 'react-router-dom';
import Moment from 'react-moment';
// Redux
import { connect } from 'react-redux';
// Tickets
import { loadTickets, getTicketID, clearTicketID, clearVerifyPID, clearCreateTicket } from "../actions/tickets";
import { clearViewTicket} from "../actions/viewTicket";
import { clearValidUploads, noViewReport } from './../actions/uploads';
import { unrespondedReport } from "../actions/tickets";


const Dashbaord = ({ isAuthenticated, user, tickets, loadTickets, getTicketID, clearTicketID, clearViewTicket, clearValidUploads, clearVerifyPID, clearCreateTicket }) => {

    useEffect(() => {
        
        clearTicketID();
        clearViewTicket();
        clearValidUploads();
        clearVerifyPID();
        clearCreateTicket();
             
        if (isEmpty(tickets)) {
            loadTickets();
        } else {
            return;
        }

    });
    
    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    
    if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
    
        return (
            
            <div className="contaner-fluid" id="dashboardContainer">

                <br/>

                <div className="row">
                        <div className="col-md-8" id="myAccount">
                            <div className="card text-center text-white" id="accountCard">
                                <div className="card-header" id="my-account">
                                    <h4 className="card-title">My Account Information</h4>
                                </div>
                                <div className="card-body">
                                    {/* Put User Table Info Here */}
                                    <table className="table table-sm">
                                        <tbody>    
                                            <tr className="h5">
                                                <th className="pt-2 pb-3 pl-1">User</th>
                                                <th className="pt-2 pb-3 pl-1">Department</th>
                                                <th className="pt-2 pb-3 pl-1">PID</th>
                                                <th className="pt-2 pb-3 pl-1">Last Login</th>
                                            </tr>

                                            <tr className="h6">
                                                <td className="pt-3 pb-3 pl-1">{user?.first_name} {user?.last_name}</td>
                                                <td className="pt-3 pb-3 pl-1">{user?.department_name}</td>
                                                <td className="pt-3 pb-3 pl-1">{user?.P_ID}</td>
                                                <td className="pt-3 pb-3 pl-1">{user?.last_login} CST</td>
                                            </tr>
                                        </tbody>
                                        <caption className="text-center pt-4 pb-0">
                                            {user ? <Link className="btn btn-secondary btn-sm btn-block align-middle mx-3" to='#' onClick={() => {window.location='https://www.tcole.texas.gov/content/background-confirmation-and-tcole-secure-share-0'}}> TSS/BCF Info </Link> : null }
                                            {user ? <Link className="btn btn-danger btn-sm btn-block align-middle mx-3" to='#' onClick={() => {window.location='mailto:secureshare@tcole.texas.gov'}}>Email TSS Support</Link> : null }
                                            {user.is_staff ? <button className="btn btn-primary btn-sm btn-block align-middle mx-3" onClick={() => unrespondedReport()}> Ticket Reponse Report </button>: null}
                                            {user.is_staff ? <button className="btn btn-primary btn-sm btn-block align-middle mx-3" onClick={() => noViewReport()}> Upload View Report</button> : null}
                                        </caption>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2" id="totalTickets">
                            <div className="card text-center text-white">
                                <div className="card-header" id="total-tickets">
                                    <h4 className="card-title">Total Tickets</h4>
                                </div>
                                <div className="card-body">
                                    {/* Put Count of Tickets with Matching 'creator_id' Here */}
                                    <table className="table table-sm">
                                        <tbody>
                                            <tr className="h5">
                                                <th className="pt-2 pb-3 pl-1">Active</th>
                                            </tr>
                                            <tr className="h6">
                                                <td className="pt-3 pb-3 pl-1">{tickets.ticket_count}</td>
                                            </tr>
                                        </tbody>
                                        <caption className="text-center pt-4 pb-0">{tickets.archived_tickets ? <Link className="btn btn-primary btn-sm btn-block align-middle" to='/archived-tickets'>Archived Tickets</Link> : null }</caption>
                                    </table>
                            </div>
                        </div>
                        </div>

                        <div className="col-md-2" id="totalRequests">
                            <div className="card text-center text-white">
                                <div className="card-header" id="total-requests">
                                    <h4 className="card-title">Total Requests</h4>
                                </div>
                                <div className="card-body">
                                    {/* Put Count of Tickets with Matching 'contact_id' Here */}
                                    <table className="table table-sm">
                                        <tbody>
                                            <tr className="h5">
                                                <th className="pt-2 pb-3">Active</th>
                                            </tr>
                                            <tr className="h6">
                                                <td className="pt-3 pb-3">{tickets.request_count}</td>
                                            </tr>
                                        </tbody>
                                        <caption className="text-center pt-4 pb-0">{tickets.archived_requests ? <Link className="btn btn-primary btn-sm btn-block align-middle" to='/archived-requests'>Archived Requests</Link> : null }</caption>
                                    </table>
                                </div>
                            </div>
                        </div>

                    

                </div>
                <br/><br/>
                <div className="row">             

                    <div className="col-md-12">
                       
                        <div className="card text-center text-white">
                            <div className="card-header" id="active-tickets">
                            <h4>Active Tickets</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>        
                                    <tr className="h5">
                                        <th className="pt-3 pb-3">Applicant Name</th>
                                        <th className="pt-3 pb-3">Applicant PID</th>
                                        <th className="pt-3 pb-3">Recipient</th>
                                        <th className="pt-3 pb-3">Recipient DEPT</th>
                                        <th className="pt-3 pb-3">Date Requested</th>
                                        <th className="pt-3 pb-3">Expiration Date</th>
                                        <th></th>
                                    </tr>

                                
                                    
                                    
                                    {tickets.active_tickets?.map((ticket, i) =>{

                                            return(<tr key={i} className="h6">
                                            <td className="pt-2 pb-1">{ticket.applicant_first_name} {ticket.applicant_last_name}</td>
                                            <td className="pt-2 pb-1">{ticket.applicant_P_ID}</td>
                                            <td className="pt-2 pb-1">{ticket.contact_id}</td>
                                            <td className="pt-2 pb-1">{ticket.contact_dept}</td>
                                            <td className="pt-2 pb-1"><Moment  format="MM-DD-YYYY  hh:mm A">{ticket.created_at}</Moment> CST</td>
                                            <td className="pt-2 pb-1"><Moment format="MM-DD-YYYY  hh:mm A" add={{days: 14}}>{ticket.created_at}</Moment> CST</td>
                                            <td className="pt-2 pb-1"><Link className="btn btn-primary btn-sm btn-block" onClick={() => getTicketID(ticket.id)} to='/ticket-view'>View Ticket</Link></td>

                                        </tr>)
                                        
                                    })
                                    }              

                                </tbody>
                            </table>
                        </div>
                    </div>

                    

                </div>

                <br/>
                <br/>

                <div className="row">             
                    <div className="col-md-12">
                        <div className="card text-center text-white">
                            <div className="card-header" id="active-requests">
                                <h4>Active Requests</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>    
                                    <tr className="h5">
                                        <th className="pt-3 pb-3">Applicant Name</th>
                                        <th className="pt-3 pb-3">Applicant PID</th>
                                        <th className="pt-3 pb-3">Requestor</th>
                                        <th className="pt-3 pb-3">Request DEPT</th>
                                        <th className="pt-3 pb-3">Date Requested</th>
                                        <th className="pt-3 pb-3">Expiration Date</th>
                                        <th></th>
                                    </tr>

                                                                
                                    {tickets.active_uploads?.map((ticket, i) =>{
                                    return( <tr key={i} className="h6">
                                            <td className="pt-2 pb-1">{ticket.applicant_first_name} {ticket.applicant_last_name}</td>
                                            <td className="pt-2 pb-1">{ticket.applicant_P_ID}</td>
                                            <td className="pt-2 pb-1">{ticket.creator_first_name} {ticket.creator_last_name}</td>
                                            <td className="pt-2 pb-1">{ticket.creator_dept}</td>
                                            <td className="pt-2 pb-1"><Moment  format="MM-DD-YYYY  hh:mm A">{ticket.created_at}</Moment> CST</td>
                                            <td className="pt-2 pb-1"><Moment format="MM-DD-YYYY  hh:mm A" add={{days: 14}}>{ticket.created_at}</Moment> CST</td>
                                            <td className="pt-2 pb-1"><Link className="btn btn-primary btn-sm btn-block" onClick={() => getTicketID(ticket.id)} to='/file-upload'>Upload File</Link></td>
                                        </tr>)
                                        
                                    })
                                } 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
            </div>


        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    tickets: state.tickets.tickets

  });

export default connect(mapStateToProps, {loadTickets, getTicketID, clearTicketID, clearViewTicket, clearValidUploads, clearVerifyPID, clearCreateTicket})(Dashbaord);