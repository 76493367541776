import React from "react";
import { connect } from 'react-redux';




const PendingInfo = ({ user }) => {


    return (
        <div className="container-fluid outerMargin">
            <div className="row">
                <div className="col-md-10">
                    <h2 className='text-center'>Thank You For Applying to Secure Share</h2>
                    <h5 className='text-center'>Your Application is being reviewed by the TCOLE staff.  Please allow 72 hours for Account Verification.</h5>
                    <hr/>
                </div>
            </div>

            <div className="col-md-10" id="myAccount">
                            <div className="card text-center text-white">
                                <div className="card-header" id="my-account">
                                    <h5 className="card-title">My Account Information</h5>
                                </div>
                                <div className="card-body">
                                    {/* Put User Table Info Here */}
                                    <table className="table table-sm">
                                        <tbody>    
                                            <tr>
                                                <th>User</th>
                                                <th>Department</th>
                                                <th>PID</th>
                                                <th>Status</th>
                                            </tr>

                                            <tr>
                                                <td>{user?.first_name} {user?.last_name}</td>
                                                <td>{user?.department_name}</td>
                                                <td>{user?.P_ID}</td>
                                                <td>{user?.status === 1 ? 'Approval Pending' : 'Not Approved'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
        
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(PendingInfo);