import {
    VALID_UPLOADS_SUCCESS,
    VALID_UPLOADS_FAIL,
    CLEAR_VALID_UPLOADS,
    GET_UPLOAD_ID_SUCCESS,
    GET_UPLOAD_ID_FAIL,
    CLEAR_UPLOAD_ID,
    GET_UPLOAD_SUCCESS,
    GET_UPLOAD_FAIL,
    CLEAR_UPLOAD,
    UPDATE_UPLOAD_SUCCESS,
    UPDATE_UPLOAD_FAIL,
    GET_OLD_UPLOAD_SUCCESS,
    GET_OLD_UPLOAD_FAIL,
    GET_VIEWDATE_SUCCESS,
    GET_VIEWDATE_FAIL,
    NO_VIEW_REPORT_SUCCESS,
    NO_VIEW_REPORT_FAIL,
} from '../actions/types';

const initialState = {
    validUploads: {},
    uploadID: [],
    upload: {},
    update: {},
    report: {}
};

export default function uploads(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case VALID_UPLOADS_SUCCESS:
            return {
                ...state,
                validUploads: payload
            }
        case GET_UPLOAD_ID_SUCCESS:
            return {
                ...state,
                uploadID: payload
            }
        case GET_OLD_UPLOAD_SUCCESS:
        case GET_UPLOAD_SUCCESS:
            return {
                ...state,
                upload: payload
            }
        case UPDATE_UPLOAD_SUCCESS:
        case UPDATE_UPLOAD_FAIL:
            return {
                ...state,
            }
        case NO_VIEW_REPORT_SUCCESS:
            return {
                ...state,
                report: payload
            }
        case NO_VIEW_REPORT_FAIL:
            return{
                ...state,
                report: {}
            }
        case CLEAR_UPLOAD_ID:
        case GET_UPLOAD_ID_FAIL:
            return{
                ...state,
                uploadID: []
            }
        case CLEAR_VALID_UPLOADS:
        case VALID_UPLOADS_FAIL:
            return {
                ...state,
                validUploads: {}
            }
        case CLEAR_UPLOAD:
        case GET_UPLOAD_FAIL:
        case GET_OLD_UPLOAD_FAIL:
            return {
                ...state,
                upload: {}
            }
        case GET_VIEWDATE_SUCCESS:
            return {
                ...state,
                update: payload
            }

        case GET_VIEWDATE_FAIL:
            return {
                ...state,
                update: {}
            }
        default:
            return state
    }
}



