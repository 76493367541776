// AUTHENTICATION
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL';
export const LOGOUT = 'LOGOUT';

// TICKETS
export const TICKETS_SUCCESS = 'TICKETS_SUCCESS';
export const TICKETS_FAIL = 'TICKETS_FAIL';
export const GET_TICKET_ID_SUCCESS = 'GET_TICKET_ID_SUCCESS';
export const GET_TICKET_ID_FAIL = 'GET_TICKET_ID_FAIL';
export const CLEAR_TICKET_ID = 'CLEAR_TICKET_ID';
export const VIEW_TICKET_SUCCESS = 'VIEW_TICKET_SUCCESS';
export const VIEW_TICKET_FAIL = 'VIEW_TICKET_FAIL';
export const CLEAR_VIEW_TICKET = 'CLEAR_VIEW_TICKET';
export const CREATE_TICKET_SUCCESS = 'TICKETS_SUCCESS';
export const CREATE_TICKET_FAIL = 'TICKETS_FAIL';
export const VERIFY_PID_SUCCESS = 'VERIFY_PID_SUCCESS';
export const VERIFY_PID_FAIL = 'VERIFY_PID_FAIL';
export const CLEAR_VERIFY_PID = 'CLEAR_VERIFY_PID';
export const SET_TICKET_CONTACT_SUCCESS = 'SET_TICKET_CONTACT_SUCCESS';
export const SET_TICKET_CONTACT_FAIL = 'SET_TICKET_CONTACT_FAIL';
export const CLEAR_TICKET_CONTACT = 'CLEAR_TICKET_CONTACT';
export const UNRESPONDED_REPORT_SUCCESS = 'UNRESPONDED_REPORT_SUCCESS';
export const UNRESPONDED_REPORT_FAIL = 'UNRESPONDED_REPORT_FAIL';

// UPLOADS
export const VALID_UPLOADS_SUCCESS = 'VALID_UPLOADS_SUCCESS';
export const VALID_UPLOADS_FAIL = 'VALID_UPLOADS_FAIL';
export const CLEAR_VALID_UPLOADS = 'CLEAR_VALID_UPLOADS';
export const GET_UPLOAD_ID_SUCCESS = 'GET_UPLOAD_ID_SUCCESS';
export const GET_UPLOAD_ID_FAIL = 'GET_UPLOAD_ID_FAIL';
export const CLEAR_UPLOAD_ID = 'CLEAR_UPLOAD_ID';
export const GET_UPLOAD_SUCCESS = 'GET_UPLOAD_SUCCESS';
export const GET_UPLOAD_FAIL = 'GET_UPLOAD_FAIL';
export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';
export const UPDATE_UPLOAD_SUCCESS = 'UPDATE_UPLOAD_SUCCESS';
export const UPDATE_UPLOAD_FAIL = 'UPDATE_UPLOAD_FAIL';
export const GET_OLD_UPLOAD_SUCCESS = 'GET_OLD_UPLOAD_SUCCES';
export const GET_OLD_UPLOAD_FAIL = 'GET_OLD_UPLOAD_FAIL';
export const GET_VIEWDATE_SUCCESS = 'GET_VIEWDATE_SUCCESS';
export const GET_VIEWDATE_FAIL = 'GET_VIEWDATE_FAIL';
export const NO_VIEW_REPORT_SUCCESS = 'NO_VIEW_REPORT_SUCCESS';
export const NO_VIEW_REPORT_FAIL = 'NO_VIEW_REPORT_FAIL';

// ACCT LOOKUP
export const DEPT_ACCOUNTS_SUCCESS = 'DEPT_ACCOUNTS_SUCCESS';
export const DEPT_ACCOUNTS_FAIL = 'DEPT_ACCOUNTS_FAIL';
