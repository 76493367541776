import {
    TICKETS_SUCCESS,
    TICKETS_FAIL,
    GET_TICKET_ID_SUCCESS,
    GET_TICKET_ID_FAIL,
    CLEAR_TICKET_ID,
    CREATE_TICKET_SUCCESS,
    CREATE_TICKET_FAIL,
    VERIFY_PID_SUCCESS,
    VERIFY_PID_FAIL,
    CLEAR_VERIFY_PID,
    SET_TICKET_CONTACT_SUCCESS,
    SET_TICKET_CONTACT_FAIL,
    CLEAR_TICKET_CONTACT,
    UNRESPONDED_REPORT_SUCCESS,
    UNRESPONDED_REPORT_FAIL,
} from '../actions/types';

const initialState = {
    tickets: {},
    ticketID: [],
    accounts: {},
    contact: {},
    report: {}
};



export default function tickets(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case TICKETS_SUCCESS:
            return {
                ...state,
                tickets: payload
            }
        case GET_TICKET_ID_SUCCESS:
            return {
                ...state,
                ticketID: payload
            }
        case VERIFY_PID_SUCCESS:
            return {
                ...state, 
                accounts: payload
            }
        case SET_TICKET_CONTACT_SUCCESS:
            return {
                ...state,
                contact: payload
            }
        case CREATE_TICKET_SUCCESS:
            return {
                ...state,
            }
        case CREATE_TICKET_FAIL:
            return {
                ...state,
            }
        case TICKETS_FAIL:
            return {
                ...state,
                tickets: {}
            }
        case UNRESPONDED_REPORT_SUCCESS:
            return {
                ...state,
                report: payload
            }
        case UNRESPONDED_REPORT_FAIL:
            return {
                ...state,
                report: {}
            }
        case CLEAR_TICKET_ID:
        case GET_TICKET_ID_FAIL:
            return {
                ...state,
                ticketID: []
            }
        case CLEAR_VERIFY_PID:
        case VERIFY_PID_FAIL:
            return {
                ...state,
                accounts: {}
            }
        case CLEAR_TICKET_CONTACT:
        case SET_TICKET_CONTACT_FAIL:
            return {
                ...state,
                contact: {}
            }
        default:
            return state
    }
}
