import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { verifyPID, setTicketContact } from '../actions/tickets';
import toast from 'react-toastify';



const TicketForm1 = ({ isAuthenticated, user, accounts, accounts_total, verifyPID, setTicketContact }) => {


    // Verify Account Form
    const [ verifyFormData, setVerifyFormData ] = useState({
        PID: ''
    });
    const { PID } = verifyFormData;
    const onVerifyChange = e => setVerifyFormData({ ...verifyFormData, [e.target.name]: e.target.value });

    const verifySubmit = e => {
        e.preventDefault();
        if(user.status !== 2){
            toast.error('You are not Authorized')
        } else {
        verifyPID(PID);
        }
    }
   

    const selectAccount = () => {
        // Change to accounts_total > 1
        if( accounts_total > 0) {
            return(
            <div>
            <div className="card text-center text-white">
            <div className="card-header" id="active-tickets">
            <h4>Please Select Correct Department </h4>
            </div>
            <table className="table table-sm">
                <tbody>        
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>PID#</th>
                        <th>Department</th>
                        <th>Department ID</th>
                        <th>Last Login</th>
                        <th></th>
                    </tr>

                    {accounts?.map((account, i) =>{
                    return( <tr key={i}>
                            <td>{account.first_name} {account.last_name}</td>
                            <td>{account.email}</td>
                            <td>{account.P_ID}</td>
                            <td>{account.department_name}</td>
                            <td>{account.department_id}</td>
                            <td>{account.last_login}</td>
                            <td><Link className="btn btn-primary btn-sm btn-block" onClick={() => setTicketContact(account)} to='ticket-form-2'>Create Ticket</Link></td>

                        </tr>)    
                    })
                    }
                </tbody>
            </table>
        </div>
        <br/>
        </div>
        
            )} else {
            return(
            <div className='text-center'>
            <h5>Enter the PID for the person you are requesting the files from.</h5><br/>
            <h5>If you do not know the contact's PID, please call or email them before making a ticket.</h5>
            </div>
            )}
    };

    if (user.status != 2) {
        return <Redirect to='/pending-info' />
    } else if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
        return (
            
        <div className="container-fluid outerMargin">
              
            <br/>

            {/* Contact PID Account Lookup */}
            <div className="row g-2 d-flex justify-content-center">

                <div className="col-md-4">
                    
                    <h5 className='d-flex justify-content-center'>Who are you Requesting a Personnel file from?</h5>            
                    <hr/>
                    <form onSubmit={e => verifySubmit(e)}>

                        <div className="form-floating mb-3">
                        <input type="integer" className="form-control" placeholder="PID" id="floatingPID" name="PID" value={PID} onChange={e => onVerifyChange(e)} required />
                        <label htmlFor="floatingInput">Enter Contact PID #</label>
                        </div>

                        <button type="submit" className="btn btn-success">Verify PID</button>

                    </form>


                </div>

            </div>
            
            <br/><hr/><br/>

            <div className="row">
                <div className="col-md-12">
                    {selectAccount()}
                </div>    
            </div>



        </div>


        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    accounts: state.tickets.accounts.accounts,
    accounts_total: state.tickets.accounts.accounts_total,
    contact: state.tickets.contact,
  });

export default connect(mapStateToProps, {verifyPID, setTicketContact})(TicketForm1);
