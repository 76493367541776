import React, {useEffect} from "react";
import { Redirect, Link } from 'react-router-dom';
import moment from "moment";
import Moment from 'react-moment';


// Redux
import { connect } from 'react-redux';
import { getValidUploads, getUploadID, clearValidUploads, clearUploadID, clearUpload } from './../actions/uploads';
import { loadTicket} from "../actions/viewTicket";


const TicketView = ({ isAuthenticated, ticketID, loadTicket, getValidUploads, viewTicket, validUploads, getUploadID, clearUploadID, clearUpload}) => {
    
    useEffect(() => {
        clearUploadID();
        clearUpload();
        clearValidUploads();

        if (isEmpty(viewTicket)) {
            loadTicket(ticketID);
        } else if (isEmpty(validUploads)){
            getValidUploads(ticketID)
        } else {
            return;
        }

        getValidUploads(ticketID);
    });

    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    const now = moment().format("MM-DD-YYYY hh:mm");

    if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
 
    

        return (
            
            <div className="contaner-fluid" id="dashboardContainer">

                <br/><br/>

                <div className="row">

                    <div className="col-md-12">
                       
                        <div className="card text-center text-white">
                            <div className="card-header" id="active-tickets">
                            <h4>Ticket Details</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>        
                                    <tr className="h5">
                                        <th>Applicant Name</th>
                                        <th>Applicant PID</th>
                                        <th>Contact</th>
                                        <th>Contact DEPT</th>
                                        <th>Date Requested</th>
                                        <th>Days Open</th>
                                    </tr>

                                    <tr className="h6">
                                        <td>{viewTicket?.applicant_first_name} {viewTicket?.applicant_last_name}</td>
                                        <td>{viewTicket?.applicant_P_ID}</td>
                                        <td>{viewTicket?.creator_first_name} {viewTicket?.creator_last_name}</td>
                                        <td>{viewTicket?.creator_dept}</td>
                                        <td>{viewTicket?.created_at} CST</td>
                                        <td><Moment diff={viewTicket?.created_at} unit="days">{now}</Moment></td>
                                    </tr>
                                
                                    
                                

                                </tbody>
                            </table>
                        </div>
                    </div>    

                </div>

                <br/><br/>

                <div className="row">

                    <div className="col-md-12">
                    
                        <div className="card text-center text-white">
                            <div className="card-header" id="active-tickets">
                            <h4>Ticket Uploads</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>        
                                    <tr className="h5">
                                        <th>File</th>
                                        <th>File Name</th>
                                        <th>Upload Date</th>
                                        <th>View Date</th>
                                        <th>Expiration Date</th>
                                        <th>File</th>
                                        <th></th>
                                    </tr>

                                    {validUploads.active_uploads?.map((upload, i) =>{
                                    return( <tr key={i} className="h6">
                                            <td>{i+1}</td>
                                            <td>{upload.user_file_name }</td>
                                            <td>{upload.upload_date} CST</td>
                                            {upload.view_date ? <td>{upload.view_date} CST</td> : <td>Not Viewed</td>}
                                            <td>{upload.expirationDate} CST</td>
                                            <td><Link className="btn btn-primary btn-sm btn-block" onClick={() => getUploadID(upload.id)} to='/upload-view'>View File</Link></td>
                                        </tr>)         
                                    })
                                    }

                                </tbody>
                            </table>

                        </div>

                    </div>    

                </div>
                <br/><br/>
            </div>

          
        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    ticketID: state.tickets.ticketID,
    viewTicket: state.viewTicket.viewTicket.ticket,
    validUploads: state.uploads.validUploads
  });

export default connect(mapStateToProps, {getValidUploads, loadTicket, getUploadID, clearValidUploads, clearUploadID, clearUpload })(TicketView);