import React, { Fragment } from "react";
import { Link } from 'react-router-dom';




const Footer = () => {
    const year = new Date().getFullYear();
return (
    <Fragment>   
            <div className="fixed-bottom" id="secureShareFooter">  
                <div className="row">
                    <div className="col-md-12">

                        <h6 className="text-center">Copyright © {year} Texas Commission on Law Enforcement | 6330 East Highway 290, STE 200 Austin, Texas 78723 | 512-936-7700 | <Link style={{ textDecoration: 'none', color: 'inherit'}} to='#' onClick={() => {window.location='mailto:secureshare@tcole.texas.gov'}}>secureshare@tcole.texas.gov</Link></h6>
                       
                    </div>
                </div>
            </div>


    </Fragment>
    );
};


export default (Footer);