import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { firstLogin, updateStatus } from '../actions/auth';
import { load_user } from './../actions/auth';



const FirstLogin = ({ firstLogin, user, updateStatus }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '' 
    });

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit  = e => {
        e.preventDefault();

        firstLogin(email, password);

    };

    useEffect(() => {         
        if (isEmpty(user)) {
            load_user();
        } else {
            updateStatus(user.id);
        }
    });
    console.log(user.id)
    
    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    if (user.status === 1) {
        return <Redirect to='/pending-info' />
    };

    return (
        <div className="container-fluid outerMargin">
            <div className="row">
                <div className="col-md-6">
                    <br/>
                    <h4>Login to Complete Application Submission</h4>
                    <hr/><br/>
                    <form onSubmit={e => onSubmit(e)}>

                        <div className="form-floating mb-3">
                        <input type="email" className="form-control" placeholder="email" id="floatingEmail" name="email" value={email} onChange={e => onChange(e)} required />
                        <label htmlFor="floatingInput">Email address</label>
                        </div>


                        <div className="form-floating mb-3">
                        <input type="password" className="form-control" placeholder="password" id="floatingPassword" name="password" value={password} onChange={e => onChange(e)} required />
                        <label htmlFor="floatingPassword">Password</label>
                        </div>


                        <div className="col-12">
                        <button type="submit" className="btn btn-success m-1">Complete Application</button>
                        <Link className="btn btn-primary m-1" to='/reset-password'>Forgot Password</Link>
                        </div>

                    </form>
                </div>
            </div>
        
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { firstLogin, updateStatus })(FirstLogin);