import React, { useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { refreshToken } from "../actions/auth";
import { getValidUploads, getUploadID, clearValidUploads, clearUploadID, clearUpload } from './../actions/uploads';
import { loadTicket} from "../actions/viewTicket";
import Moment from 'react-moment';
 
const ArchivedUploads = ({ isAuthenticated, ticketID, loadTicket, getValidUploads, viewTicket, validUploads, getUploadID, clearUploadID, clearUpload, user}) => {
    
    useEffect(() => {
        clearUploadID();
        clearUpload();
        clearValidUploads();

        if (isEmpty(viewTicket)) {
            loadTicket(ticketID);
        } else if (isEmpty(validUploads)){
            getValidUploads(ticketID)
        } else {
            return;
        }

        getValidUploads(ticketID);
    });

    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

  if (!isAuthenticated || !ticketID || user.status !== 2) {
    return <Redirect to='/login' />
    }    


  return (
   <div className='container-fluid outerMargin'>

        <div className="row">
            <div className="col-md-12">
                <div className="card text-center text-white">
                    <div className="card-header" id="active-tickets">
                    <h4>Ticket Details</h4>
                    </div>
                    <table className="table table-sm">
                        <tbody>        
                            <tr>
                                <th className="pt-3 pb-3">Applicant Name</th>
                                <th className="pt-3 pb-3">Applicant PID</th>
                                <th className="pt-3 pb-3">Requestor</th>
                                <th className="pt-3 pb-3">Request DEPT</th>
                                <th className="pt-3 pb-3">Recipient</th>
                                <th className="pt-3 pb-3">Recipient DEPT</th>
                                <th className="pt-3 pb-3">Date Requested</th>
                            </tr>
                            
                            <tr className="h6">
                                <td className="pt-3 pb-3">{viewTicket?.applicant_first_name} {viewTicket?.applicant_last_name}</td>
                                <td className="pt-3 pb-3">{viewTicket?.applicant_P_ID}</td>
                                <td className="pt-3 pb-3">{viewTicket?.creator_first_name} {viewTicket?.creator_last_name}</td>
                                <td className="pt-3 pb-3">{viewTicket?.creator_dept}</td>
                                <td className="pt-3 pb-3">{viewTicket?.contact_id}</td>
                                <td className="pt-3 pb-3">{viewTicket?.contact_dept}</td>
                                <td className="pt-3 pb-3"><Moment  format="MM-DD-YYYY  hh:mm A">{viewTicket?.created_at}</Moment> CST</td>
                            </tr>
                                
                        </tbody>
                    </table>
                </div>
            </div> 
        </div>

        <br/><br/>

     {/* Shows the upload metadata in table */}
     <div className="row">
        <div className="col-md-12">
            {validUploads.archived_uploads ? <div className="card text-center text-white">
                                <div className="card-header" id="active-tickets">
                                <h4>Archived Uploads</h4>
                                </div>
                                <table className="table table-sm">
                                    <tbody>        
                                        <tr className="h5">
                                            <th className="pt-3 pb-3">File</th>
                                            <th className="pt-3 pb-3">File Name</th>
                                            <th className="pt-3 pb-3">Upload Date</th>
                                            <th className="pt-3 pb-3">Expiration Date</th>
                                        </tr>

                                        { validUploads.archived_uploads ? validUploads.archived_uploads.map((upload, i) =>{
                                        return( <tr key={i} className="h6">
                                                <td className="pt-2 pb-1">{i+1}</td>
                                                { upload.user_file_name ? <td className="pt-2 pb-1">{ upload.user_file_name }</td> : <td className="pt-2 pb-1">{ upload.fileName }</td>}
                                                { upload.upload_date ? <td className="pt-2 pb-1">{upload.upload_date} CST</td> : <td></td>}
                                                <td className="pt-2 pb-1">{upload.expirationDate} CST</td>
                                            </tr>)    
                                        }) :
                                        <tr className="h6">
                                            <td className="pt-2 pb-1">No Uploads</td>
                                        </tr>
                                        }

                                    </tbody>
                                </table>

                            </div> : null }
        </div>
     </div>

      <br/><br/><br/>

   </div>
  );
}
 
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    ticketID: state.tickets.ticketID,
    viewTicket: state.viewTicket.viewTicket.ticket,
    validUploads: state.uploads.validUploads
  });

export default connect(mapStateToProps, { refreshToken, getValidUploads, loadTicket, getUploadID, clearValidUploads, clearUploadID, clearUpload })(ArchivedUploads);