import './App.css';
import React from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// Error toasts(messages)
import { ToastContainer } from 'react-toastify';

// Redux
import { Provider } from 'react-redux';
import store from './store';
// Components
import Layout from './hocs/Layout';
import Footer from './components/Footer'
import NotFound from './components/NotFound';
// Containers
import Dashboard from './containers/Dashboard';
import Activate from './containers/Activate';
import ConfirmResetPassword from './containers/ConfirmResetPassword';
import Login from './containers/Login';
import FirstLogin from './containers/FirstLogin';
import ResetPassword from './containers/ResetPassword';
import Signup from './containers/Signup';
import TicketForm2 from './containers/TicketForm2';
import TicketForm1 from './containers/TicketForm1';
import TicketView from './containers/TicketView';
import FileUpload from './containers/FileUpload';
import UploadView from './containers/UploadView';
import PendingInfo from './containers/PendingInfo';
import ArchivedRequests from './containers/ArchivedRequests';
import ArchivedTickets from './containers/ArchivedTickets';
import ArchivedUploads from './containers/ArchivedUploads';
import UserLookup from './containers/UserLookup';



const App = () => (
    <Provider store={store}>
        <Router>
            <ToastContainer position="bottom-center" />
            <Layout>
                <Switch>
                    <Route exact path='/' component={Dashboard} />
                    <Route exact path='/pending-info' component={PendingInfo} />
                    <Route exact path='/user-lookup' component={UserLookup} />
                    <Route exact path='/ticket-form-1' component={TicketForm1} />
                    <Route exact path='/ticket-form-2' component={TicketForm2} />
                    <Route exact path='/ticket-view' component={TicketView} />
                    <Route exact path='/upload-view' component={UploadView} />
                    <Route exact path='/archived-requests' component={ArchivedRequests} />
                    <Route exact path='/archived-tickets' component={ArchivedTickets} />
                    <Route exact path='/archived-uploads' component={ArchivedUploads}/>
                    <Route exact path='/file-upload' component={FileUpload} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/first-login' component={FirstLogin} />
                    <Route exact path='/signup' component={Signup} />
                    <Route exact path='/reset-password' component={ResetPassword} />
                    <Route exact path='/password/reset/confirm/:uid/:token' component={ConfirmResetPassword} />
                    <Route exact path='/activate/:uid/:token' component={Activate} />
                    <Route component={NotFound} />
                </Switch>
                <Footer />
            </Layout>
        </Router>
    </Provider>
);

export default App;