import axios from 'axios';
import { toast } from 'react-toastify';

import{
    TICKETS_SUCCESS,
    TICKETS_FAIL,
    GET_TICKET_ID_SUCCESS,
    GET_TICKET_ID_FAIL,
    CLEAR_TICKET_ID,
    CREATE_TICKET_SUCCESS,
    CREATE_TICKET_FAIL,
    VERIFY_PID_SUCCESS,
    VERIFY_PID_FAIL,
    CLEAR_VERIFY_PID,
    SET_TICKET_CONTACT_SUCCESS,
    SET_TICKET_CONTACT_FAIL,
    CLEAR_TICKET_CONTACT,
} from './types';

// Load ALL Tickets for Given User
export const loadTickets = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/user/`, config);
            dispatch({
                type: TICKETS_SUCCESS,
                payload: res.data
            });

        } catch (err) {
            dispatch({
                type: TICKETS_FAIL
            });
            // toast.error('Tickets Failed to Load - Try Refreshing Page')
        }
    } else {
        dispatch({
            type: TICKETS_FAIL
        });
    }
};

// Grabs Ticket ID from Dashboard for FileUpload & TicketView
export const getTicketID = (ticketID) => async dispatch => {
    if(!ticketID) {
        dispatch({
            type: GET_TICKET_ID_FAIL
        });

    } else {
        dispatch({
            type: GET_TICKET_ID_SUCCESS,
            payload: ticketID
        });
    }
};

// Clears TicketID upon Dashboard Page Load
export const clearTicketID = () => dispatch => {
    dispatch({
        type: CLEAR_TICKET_ID
    })
}


// Clears tickets/accounts upon Dashboard Page Load
export const clearVerifyPID = () => dispatch => {
    dispatch({
        type: CLEAR_VERIFY_PID
    })
}

// Clears createTicket upon Dashboard Page Load
export const clearCreateTicket = () => dispatch => {
    dispatch({
        type: CLEAR_TICKET_CONTACT
    })
}

export const createTicket = ({ creator_id, contact_id, applicant_first_name,
applicant_last_name, applicant_P_ID }) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        const body = JSON.stringify({ creator_id, contact_id, applicant_first_name, applicant_last_name, applicant_P_ID});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/tickets/create/`, body, config);
            dispatch({
                type: CREATE_TICKET_SUCCESS,
                payload: res.data
            });

            toast.success('Ticket Created')
            window.setTimeout(function () {
                window.location.href = '/';
              }, 2000);

        } catch (err) {
            dispatch({
                type: CREATE_TICKET_FAIL
            });
            toast.error('Ticket Submit Failed - Please contact TCOLE')
        }
    } else {
        dispatch({
            type: CREATE_TICKET_FAIL
        });
        toast.error('Invalid Ticket Data - Please Check Input Fields')
    }
};


export const verifyPID = (PID) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 


        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/accounts/return-accounts/${PID}`, config);

            if(res.data.accounts_total === 0) {
                toast.error('PID Verification Failed - Invalid PID or User non-existent')
                dispatch({
                    type: VERIFY_PID_FAIL
                });
            } else {
                dispatch({
                    type: VERIFY_PID_SUCCESS,
                    payload: res.data
                });
            };
        } catch (err) {
            dispatch({
                type: VERIFY_PID_FAIL
            });
            toast.error('PID Verification Failed - Invalid PID or User non-existent')
        }
    } else {
        return;
    }
};


export const setTicketContact = (account) => async dispatch => {
    if(!account) {
        dispatch({
            type: SET_TICKET_CONTACT_FAIL
        });

    } else {
        dispatch({
            type: SET_TICKET_CONTACT_SUCCESS,
            payload: account
        });
    }
}

export const unrespondedReport = () => {
    const config = {
        headers: {
            'Content-Type': 'text/csv',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    }; 
    axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/unresponded-report/`, config)
    .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv'});
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.download = 'TSS_unresponded_tickets_report.csv';
        tempLink.setAttribute('taget', '_blank');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    })
    .catch((error) => {
        console.log(error);
        console.log(error.response);
    })
}

