import axios from 'axios';
import { toast } from 'react-toastify';

import{
    VIEW_TICKET_SUCCESS,
    VIEW_TICKET_FAIL,
    CLEAR_VIEW_TICKET
} from './types';


// Load Specific Ticket Details
export const loadTicket = (ticketID) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
        }
    }; 

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/tickets/ticket/${ticketID}`, config);
        dispatch({
            type: VIEW_TICKET_SUCCESS,
            viewTicket: res.data
        });

    } catch (err) {
        dispatch({
            type: VIEW_TICKET_FAIL
        });
        toast.error('Ticket Load Failed - Try Refreshing Page')
    }
};

// Clears viewTicket upon Dashboard Page Load
export const clearViewTicket = () => dispatch => {
    dispatch({
        type: CLEAR_VIEW_TICKET
    })
};