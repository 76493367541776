import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { deptAccounts } from '../actions/userLookup';
import { toast, ToastContainer } from 'react-toastify';
import { setTicketContact } from '../actions/tickets';
import copyIcon from '../images/copy_icon.png';


const UserLookup = ({ isAuthenticated, user, deptAccounts, searchResults, setTicketContact }) => {

    const [showSearch, setShowSearch] = useState(false);

    const [ verifySearchFormData, setVerifySearchFormData ] = useState({
        searchField: ''
    });

    const { searchField } = verifySearchFormData;

    const onVerifySearchChange = e => setVerifySearchFormData({ ...verifySearchFormData, [e.target.name]: e.target.value });

    const verifySearchSubmit = e => {
        e.preventDefault();
        if(user.status !== 2){
            toast.error('You are not Authorized')
        } else {
        deptAccounts(searchField);
        setShowSearch(true);
        }
    }

    const [copyText, setCopyText] = useState('');

    const clickToCopy = () => {
        if (copyText == undefined){
            return
        } else {
        navigator.clipboard.writeText(copyText);
        navigator.clipboard.readText(copyText);
        toast.success("PID Copied", {autoClose: 2000})
        }
    }
   
    const selectSearchAccount = () => {
        
        if( showSearch === true ) {
            return(
            <div>
                <div className="card text-center text-white">
                    <div className="card-header" id="active-tickets">
                    <h4>Department Investigators</h4>
                    </div>

                    <table className="table table-sm">
                        <tbody>        
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>PID#</th>
                                <th>Department</th>
                                <th>Department ID</th>
                                <th>Last Login</th>
                            </tr>

                            {searchResults?.map((user, i) =>{
                            return( <tr key={i}>
                                    <td>{user.first_name} {user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td id="copyRow">{user.P_ID} <img id="copyIcon" alt="copyIcon" onClick={() => (setCopyText(user.P_ID), clickToCopy())} src={copyIcon}/></td>
                                    <td>{user.department_name}</td>
                                    <td>{user.department_id}</td>
                                    <td>{user.last_login}</td>
                                </tr>)    
                            })
                            }
                        </tbody>
                    </table>
                    <ToastContainer position="bottom-center" />
                </div>
                <br/><br/><br/>
            </div>
        
            )} else return
    };

        if (!isAuthenticated) {
        return <Redirect to='/login' />
        } else if (user.status != 2) {
        return <Redirect to='/pending-info' />
        } 

        return (
            
        <div className="container-fluid outerMargin">
              
            <br/>

            {/* Dept User Lookup */}
            <div className="row g-2 d-flex justify-content-center">

                <div className="col-md-4">
                    
                    <h5 className='d-flex justify-content-center'>Search for Department Contacts:</h5>            
                    <hr/>
                    <form onSubmit={e => verifySearchSubmit(e)}>

                        <div className="form-floating mb-3">
                        <input type="text" className="form-control" placeholder="Department Name"  name="searchField" value={searchField} onChange={e => onVerifySearchChange(e)} required />
                        <label htmlFor="floatingInput">Enter Department Name</label>
                        </div>

                        <button type="submit" className="btn btn-success">Search</button>

                    </form>


                </div>

            </div>
            
            <br/><hr/><br/>

            <div className="row">
                <div className="col-md-12">
                    {selectSearchAccount()}
                </div>    
            </div>



        </div>


        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    searchResults: state.userLookup.searchResults.searchResults,
    contact: state.tickets.contact
});

export default connect(mapStateToProps, { deptAccounts, setTicketContact})(UserLookup);