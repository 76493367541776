import React, {useEffect, useState} from "react";
import { Redirect } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { refreshToken } from "../actions/auth";
import { getUpload, updateUpload, getViewDate } from './../actions/uploads';



const UploadView = ({ isAuthenticated, refreshToken, getUpload, uploadID, upload, updateUpload, getViewDate, update }) => {

    const [showWarning, setShowWarning] = useState(true);
    const [showPDF, setShowPDF] = useState(false);

    useEffect(() => {
        refreshToken();
        if (isEmpty(upload)) {
            getUpload(uploadID);
        } else {
            return;
        }
        getViewDate(uploadID); 
    });

    const onClick = () => {
        setShowWarning(false);
        setShowPDF(true);
        uploadViewed(update);
    }

    const uploadViewed = (update) => {
        const serverTime = update.serverTime;
        const viewDate = update.viewDate['view_date'];

        console.log("serverTime: ", serverTime, "viewDate: ", viewDate);
        if (viewDate === null) {
            updateUpload(uploadID, serverTime);
        }

        else {
            return;

        }
    }


    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
    
    
        return (

            <div className="contaner-fluid" id="dashboardContainer">
                <br/>
                {showWarning ? <div className="row justify-content-md-center">
                    <div className="col-md-6">
                        <div className="card text-center">
                            <div className="card-header text-white" id="confidential">
                               <h5>Restricted Use of Confidential Information </h5>
                            </div>
                            <div className="card-body">
                                <p className="card-text">The Background Requestor will keep all the Confidential Information strictly confidential, and will not, either directly or indirectly, disclose, allow access to, transmit, transfer, use or reproduce any of the Confidential Information in any manner or permit any unauthorized access or use.</p>
                                <button onClick={onClick} className="btn btn-primary">I Agree</button>
                            </div>
                        </div>
                    </div>
                </div> : null}

                <br/>

                {showPDF ? <div className="col-md-12" id="uploadView">
                         {upload ? <embed src={upload}  type="application/pdf" width="100%" height="100%" /> : <h2>Loading...</h2>}
                </div> : null}

            </div>
 
        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    uploadID: state.uploads.uploadID,
    upload: state.uploads.upload.stream,
    update: state.uploads.update
  });

export default connect(mapStateToProps, { refreshToken, getUpload, updateUpload, getViewDate })(UploadView);