import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createTicket } from '../actions/tickets';
import toast from 'react-toastify';



const TicketForm2 = ({ isAuthenticated, user, contact, createTicket }) => {

    const [showSubmit, setShowSubmit] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);

    // Create Ticket Form
    const [formData, setFormData] = useState({
        creator_id: user.id,
        contact_id: contact.id,
        applicant_first_name: '',
        applicant_last_name: '',
        applicant_PID: null
    });

    const { creator_id, contact_id, applicant_first_name, applicant_last_name, applicant_PID } = formData;


    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit  = e => {
        e.preventDefault();

       setShowSubmit(false);
       setShowProcessing(true);

       const applicant_P_ID = parseInt(applicant_PID);
        if (user.status !== 2) {
            toast.error('You are not Authorized')
        } else {
        createTicket({creator_id, contact_id, applicant_first_name, applicant_last_name, applicant_P_ID});
        }
    };

   if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
    
        return (
    <div className="container-fluid outerMargin"> 
       
       <form onSubmit={e => onSubmit(e)}>
            <div className='row justify-content-center'>
                <div className='col-md-3'>
                    
                    {/* Contact First Name */}
                    <div className="col-md">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="deptContFN" placeholder={contact.first_name} value={contact.first_name} disabled />
                            <label htmlFor="floatingContFN">Contact First Name</label>
                        </div>
                    </div>
                </div>

                <div className='col-md-3'>
                    {/* Contact Last Name */}
                    <div className="col-md">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="deptContLN" placeholder={contact.last_name} value={contact.last_name} disabled />
                            <label htmlFor="floatingContLN">Contact Last Name</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-6'>
                {/* Contact Email */}
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="departmentEmail" placeholder={contact.email}  value={contact.email}disabled/>
                        <label htmlFor="floatingDeptEmail">Email</label>
                    </div>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-3'>
                    {/* Contact Department Name */}
                    <div className="form-floating mb-3">
                        <input type='text' className='form-control' id='contactDeptNameForm' placeholder={contact.department_name} value={contact.department_name} disabled />
                        <label htmlFor="floatingDeptName">Department Name</label>
                    </div>
                </div>

                <div className='col-md-3'>
                    {/* Contact Department ID */}
                    <div className="form-floating mb-3">    
                        <input type='integer' className='form-control' id='contactDeptNameForm' name='department_id' placeholder={contact.department_id} value={contact.department_id} disabled />
                        <label htmlFor="floatingDeptID">Department ID</label>
                    </div>
                </div>
            </div>

            <br/>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    
                    <h2>Applicant Information:</h2>
                    
                    <hr/>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="applicantFirst" name='applicant_first_name' placeholder="First Name" value={applicant_first_name} onChange={e => onChange(e)} required />
                        <label htmlFor="floatingFirstName">Applicant First Name</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="applicantLast" name='applicant_last_name' placeholder="Last Name" value={applicant_last_name} onChange={e => onChange(e)} required />
                        <label htmlFor="floatingLastName">Applicant Last Name</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="integer" className="form-control" id="applicantPID" name='applicant_PID' placeholder="PID" value={applicant_PID} onChange={e => onChange(e)} required />
                        <label htmlFor="floatingPID">Applicant PID #</label>
                    </div>

                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    { showSubmit ? <button type='submit' className='btn btn-primary m-2'>Finish</button> : null }
                    { showProcessing ? <h6>Please Wait.</h6> : null }
                </div>
            </div>
        </form>


    </div>

        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    contact: state.tickets.contact

  });

export default connect(mapStateToProps, {createTicket})(TicketForm2);