import React, {useEffect} from "react";
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadTickets, getTicketID } from "../actions/tickets";
import Moment from 'react-moment';


const ArchivedRequests = ({ isAuthenticated, tickets, loadTickets, getTicketID}) => {
    
    useEffect(() => {
        if (isEmpty(tickets)) {
            loadTickets();
        } else {
            return;
        }
    });

    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
            return false;
        }
        return true;
    }

    if (!isAuthenticated) {
        return <Redirect to='/login' />
    }
 
    

        return (
            
            <div className="contaner-fluid outerMargin">

                <br/><br/>

                <div className="row">             

                    <div className="col-md-12">
                       
                        <div className="card text-center text-white">
                            <div className="card-header" id="active-tickets">
                            <h4>Archived Requests</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>        
                                    <tr className="h5">
                                        <th className="pt-3 pb-3">Applicant Name</th>
                                        <th className="pt-3 pb-3">Applicant PID</th>
                                        <th className="pt-3 pb-3">Requestor</th>
                                        <th className="pt-3 pb-3">Request DEPT</th>
                                        <th className="pt-3 pb-3">Upload Requested</th>
                                        <th></th>
                                    </tr>

                                
                                    
                                    
                                    {tickets.archived_requests?.slice(0).reverse().map((ticket, i)=>{
                                            
                                            return(<tr key={i} className="h6">
                                            <td className="pt-2 pb-1">{ticket.applicant_first_name} {ticket.applicant_last_name}</td>
                                            <td className="pt-2 pb-1">{ticket.applicant_P_ID}</td>
                                            <td className="pt-2 pb-1">{ticket.creator_first_name} {ticket.creator_last_name}</td>
                                            <td className="pt-2 pb-1">{ticket.creator_dept}</td>
                                            <td className="pt-2 pb-1"><Moment  format="MM-DD-YYYY  hh:mm A">{ticket?.created_at}</Moment> CST</td>
                                            <td className="pt-2 pb-1"><Link className="btn btn-primary btn-sm btn-block" onClick={() => getTicketID(ticket.id)} to='/archived-uploads'>Archived Uploads</Link></td>
                                        </tr>)
                                        
                                    })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    

                </div>

                <br/><br/><br/>
            </div>

          
        );
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    tickets: state.tickets.tickets
  });

export default connect(mapStateToProps, {loadTickets, getTicketID})(ArchivedRequests);