import { combineReducers } from 'redux';
import auth from './auth';
import tickets from './tickets';
import viewTicket from './viewTicket'
import uploads from './uploads';
import userLookup from './userLookup'

export default combineReducers({
    auth,
    tickets,
    viewTicket,
    uploads, 
    userLookup
});