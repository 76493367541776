import {
   DEPT_ACCOUNTS_SUCCESS,
   DEPT_ACCOUNTS_FAIL
} from '../actions/types';

const initialState = {
    searchResults: {}
}


export default function deptAccounts(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case DEPT_ACCOUNTS_SUCCESS:
            return {
                ...state,
                searchResults: payload
            }
        case DEPT_ACCOUNTS_FAIL:
            return {
                ...state,
                searchResults: {},

            }
        default:
            return state
    }
}
