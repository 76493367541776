import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { refreshToken } from "../actions/auth";
import { getValidUploads, updateUpload } from "../actions/uploads";
import axios from 'axios';

import Message from '../components/Message';
import Progress from '../components/Progress';


 
const FileUpload = ({ isAuthenticated, user, refreshToken, ticketID, getValidUploads, validUploads }) => {

  useEffect(() => {
    // getValidUploads shows the upload in table after it has finished
  getValidUploads(ticketID);
  refreshToken();

  }, [getValidUploads, ticketID, refreshToken]);


  const [message, setMessage] = useState('');
  const [showSubmit, setShowSubmit] = useState(true);
  const [file, setFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const chooseUpload = async (e) => {
    setFile(e.target.files[0]);
  }

  const directUploadStart = ({ fileName, file_type}) => {

    return axios.post(`${process.env.REACT_APP_API_URL}/api/uploads/direct/start/`, {fileName: fileName, file_type: file_type, ticket_id: ticketID}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${localStorage.getItem('access')}`,
        'Accept': 'application/json'
      }
    })
  };
  
  const directUploadDo = ({ data, file }) => {
    const postData = new FormData();
  
    for (const key in data?.fields) {
      postData.append(key, data.fields[key]);
    }
  
    postData.append('file', file);
  
    return axios.post(data.url, postData, {
      
      onUploadProgress: progressEvent => {
      
        setUploadPercentage(
        parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ));
        }
      })

    .then(() => Promise.resolve({ fileId: data.id }));
  };
  
  
  const directUploadFinish = ({ data }) => {
  
    return axios.post(
      `${process.env.REACT_APP_API_URL}/api/uploads/direct/finish/`,
      { file_id: data.id, ticket_id: ticketID }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          'Accept': 'application/json'
        }
      }
    );
  };
  
  
 

  const onSubmit = async event => {
    event.preventDefault()

    setShowSubmit(false);


    if (file) {
      directUploadStart({
        fileName: file.name,
        file_type: file.type,
        ticket_id: ticketID

      })
        .then((response) =>
          directUploadDo({ data: response.data, file })
            // .then(() => console.log(response.data, file))
            .then(() => directUploadFinish({ data: response.data, ticket_id: ticketID }))
            .then(() => {
              setMessage('File upload completed!');
              setTimeout(() => setUploadPercentage(0), 6000);
              setShowSubmit(true);
            })
        )
        .catch((error) => {
          console.log(error.message)
          setMessage('File upload failed!');
        });
    }
  };



  if (!isAuthenticated || !ticketID || user.status !== 2) {
    return <Redirect to='/login' />
    }    


  return (
   <div className='container-fluid outerMargin'>

     <div className="row justify-content-md-center">
        <div className="col-md-8">
            <div className="card">
                <div className="card-header text-white text-center" id="confidential">
                  <h2>IMPORTANT:</h2>
                </div>
                
                <div className="card-body">
                  <br/>
                    <h5>TCOLE Secure Share is <u>NOT</u> intended for the sharing of:</h5>
                    <br/>
                        <ul>
                          <b><li> Criminal Justice Information (CJI) or Criminal History Information (CHI)</li></b><br/>
                          <b><li> Copies of documents such as Social Security Cards, Birth Certificates, or Driver's License(s)</li></b>
                        </ul>
                    
                </div>
            </div>
        </div>
      </div> 

      <br/><br/><hr/><br/><br/>

     {message ? <Message msg={message} /> : null}

     <form onSubmit={onSubmit}>

        <div className='custom-file mb-4'>

          <input
            type='file'
            className='custom-file-input m-2'
            style={{ width: '100%' }}
            id='customFile'
            accept=".pdf, application/pdf"
            onChange={(e) => {chooseUpload(e)}}
          />

        </div>

        <br/><hr/><br/>

        <Progress percentage={uploadPercentage} />

        {showSubmit ? <input
          type='submit'
          value='Upload'
          className='btn btn-primary btn-block mt-4'
        /> : null}

      </form>

      <br/><br/><br/>


     {/* Shows the finished upload in table */}
     {validUploads.active_uploads ? <div className="card text-center text-white">
                            <div className="card-header" id="active-tickets">
                            <h4>Ticket Uploads</h4>
                            </div>
                            <table className="table table-sm">
                                <tbody>        
                                    <tr>
                                        <th>File</th>
                                        <th>File Name</th>
                                        <th>Upload Date</th>
                                        <th>Expiration Date</th>
                                    </tr>

                                    {validUploads.active_uploads?.map((upload, i) =>{
                                    return( <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{upload.user_file_name}</td>
                                            <td>{upload.upload_date} CST</td>
                                            <td>{upload.expirationDate} CST</td>
                                        </tr>)
                                    
                                        
                                    })
                                    }

                                </tbody>
                            </table>

                        </div> : null }
      <br/><br/><br/>
   </div>
  );
}
 
const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    ticketID: state.tickets.ticketID,
    validUploads: state.uploads.validUploads
  });

export default connect(mapStateToProps, { refreshToken, updateUpload, getValidUploads })(FileUpload);